import { useEffect } from "react";
import { withRouter } from 'react-router-dom';


const PageScrollTop = (props) => {
   
    useEffect(() => {
        if (window.location.pathname.includes('/portfolio/') === true) {
            window.scrollTo({
                top: 610,
                behavior: 'smooth',
            });
        }
        else {
            window.scrollTo(0, 0);
        }
    });


    return props.children;
};
export default withRouter(PageScrollTop);