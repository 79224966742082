// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import MainDemo from './home/MainDemo';

// Element Layout
import Service from "./elements/Service";
import About from "./elements/About";
import Contact from "./elements/Contact";
import Faq from "./elements/Faq";
import PrivacyPolicy from "./elements/Privacy";
import TermsCondition from "./elements/TermsCondition";
import error404 from "./elements/error404";

// Blocks Layout

import Portfolio from "./blocks/Portfolio";
import PortfolioWordpress from "./blocks/PortfolioWordpress";
import PortfolioEcommerce from "./blocks/PortfolioEcommerce";
import PortfolioWebdesign from "./blocks/PortfolioWebdesign";
import PortfolioCustomphp from "./blocks/PortfolioCustomphp";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainDemo}/>

                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/services`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/terms-condition`} component={TermsCondition}/>

                        {/* Blocks Elements  */}
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio/:id`} component={Portfolio}/>
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/portfolio/wordpress`} component={PortfolioWordpress}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio/ecommerce`} component={PortfolioEcommerce}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio/webdesign`} component={PortfolioWebdesign}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio/customphp`} component={PortfolioCustomphp}/> */}
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();