import React, { Component, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdMailOutline } from "react-icons/md";
import Loader from "../../component/loader/Loader";
const baseURL = process.env.REACT_APP_API_URL;
const siteURL = process.env.REACT_APP_URL;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      email: "",
      loader: false,
    };

    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  handleChange(e) {
    this.setState({ email: e.target.value });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}

  render() {
    let pathurl = "";
    if (window.location.pathname.includes("/portfolio/") === true) {
      pathurl = window.location.pathname.split("/").pop();
    }

    const handleShow = (e) => {
      e.preventDefault();
      this.setState({ show: true });
    };
    const handleClose = (e) => {
      e.preventDefault();
      this.setState({ show: false });
    };
    const handleOnSubmit = (e) => {
      e.preventDefault();
      this.setState({ loader: true });
      fetch(baseURL + "reqcompanyprofile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.email,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ loader: false });
          this.setState({ email: "" });
          this.setState({ msg: true });
          this.setState({ show: false });
          const pdfUrl = siteURL + "Company_Profile_Rigic_Global_Solutions.pdf";
          const link = document.createElement("a");
          link.href = pdfUrl;
          link.download = "Company_Profile_Rigic_Global_Solutions.pdf"; // specify the filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    };

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = (
        <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />
      );
    } else if (logo === "dark") {
      logoUrl = (
        <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />
      );
    } else if (logo === "symbol-dark") {
      logoUrl = (
        <img
          src="/assets/images/logo/logo-symbol-dark.png"
          alt="Digital Agency"
        />
      );
    } else if (logo === "symbol-light") {
      logoUrl = (
        <img
          src="/assets/images/logo/logo-symbol-light.png"
          alt="Digital Agency"
        />
      );
    } else {
      logoUrl = (
        <img
          src="/assets/images/logo/logo-landcape.png"
          alt="Rigic-logo"
          height="70"
          width="230"
        />
      );
    }

    return (
      <header
        className={`header-area formobile-menu header--fixed header--static ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a aria-label="rigic-home" href="/">
                {logoUrl}
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <NavLink
                    aria-label="home"
                    exact
                    activeClassName="active"
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    aria-label="about"
                    activeClassName="active"
                    to="/about-us"
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    aria-label="services"
                    activeClassName="active"
                    to="/services"
                  >
                    Services
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    aria-label="portfolio"
                    activeClassName="active"
                    to={`/portfolio/${pathurl ? pathurl : "all"}`}
                  >
                    Portfolio
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    aria-label="contact"
                    activeClassName="active"
                    to="/contact"
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </nav>

            {/* Company Profile */}

            <a
              aria-label="company-profile"
              onClick={handleShow}
              className="rn-btn download-pdf-btn"
              href="/contact"
            >
              <span>Company Profile</span>
            </a>

            <Modal show={this.state.show} onHide={handleClose}>
              <div className="modal-wrap">
                <div onClick={handleClose} className="model-close-btn">
                  <img
                    src="/assets/images/icon/close-icon.svg"
                    alt="close-icon"
                  />
                </div>
                <div className="model-left">
                  <div className="logo-wrapper">
                    <img
                      src="/assets/images/logo/logo-rigic.png"
                      alt="modal-logo"
                    />
                  </div>
                </div>
                <div className="model-right">
                  <form action="" onSubmit={handleOnSubmit}>
                    <div className="modal-body-wrap">
                      <h4>Download Company Profile PDF</h4>
                      <p>
                        Thank you for your interest! Enter your email address
                        below to download it instantly.
                      </p>
                      <div className="rn-form-group">
                        {/* <input 
                  type="email"
                  name="email"
                  placeholder="Your Email*"
                  required
                  /> */}

                        <div className="rn-form-group mb-3 input-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="* Your Email"
                            value={this.state.email}
                            required
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            //onChange={e => setEmail(e.target.value)} value={email}
                            className=""
                          />
                          {/* <Button variant="" id="button-addon2" type="submit" >
                            <MdMailOutline />
                        </Button> */}
                        </div>
                      </div>
                      <div className="rn-form-group checkbox-wrapper">
                        <input
                          required
                          type="checkbox"
                          id="accept"
                          name="term"
                          value="term"
                        />
                        <label for="accept">
                          By submitting your email, you agree to receive
                          occasional updates and promotions from Us.
                        </label>
                      </div>
                    </div>
                    <div className="modal-footer-wrap">
                      <input
                        type="submit"
                        className="rn-btn download-pdf-btn m-0"
                        value="Submit & Download "
                      />
                      {this.state.loader ? <Loader /> : ""}
                    </div>
                  </form>
                </div>
              </div>
            </Modal>

            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--10">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
